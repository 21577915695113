import React from 'react'
import { CFView, CFImage, DefaultScreen, MobileScreen } from 'components'
import { foodGallery } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage src={foodGallery} w="100%" alt="Food Gallery" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          column
          center
          maxWidth="1400px"
          m="0 auto"
          h="650px"
          image={`url(${foodGallery}) center / cover no-repeat`}
          style={{ backgroundAttachment: 'fixed' }}
        ></CFView>
      </DefaultScreen>
    </CFView>
  )
}
