import React from 'react'
import {
  CFImage,
  CFView,
  CFText,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, logo, mobileHero, mobileHeroText } from 'images'
import ViewMenuButton from './ViewMenuButton'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100vh"
          maxHeight="650px"
          w="100%"
          image={`url(${mobileHero}) center / cover no-repeat`}
          column
          justifyStart
          alignCenter
        >
          <CFImage src={logo} w="100%" maxWidth="250px" alt="About" mt="40px" />
          <CFView w="100%" column justifyStart alignCenter>
            <CFView
              bold
              white
              style={{ fontSize: 32 }}
              mt="20px"
              w="85%"
              textCenter
            >
              Delicious & Healthy Japanese Food
            </CFView>
            <CFView pulsate textCenter pv="20px" w="70%">
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="690px"
          w="100%"
          image={`url(${hero}) center / cover no-repeat`}
          zIndex={90}
          column
          justifyBetween
          alignCenter
        >
          <Header />
          <CFView row center bg="rgba(32,35,90,0.9)" ph="60px" pv="3px">
            <CFView bold white style={{ fontSize: 32 }}>
              Welcome To Sushi Moon!
            </CFView>
            <CFView row center mt="5px">
              <CFView ml="30px" pulsate>
                <OrderPickupButton />
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
